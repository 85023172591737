<template>
  <main-layout>
    <h2>
      Find out where you can see
      <i>The Cold Hearts</i> next:
    </h2>

    <h3 style="margin-bottom: 1.5em;">In addition to the dates below, come see us <b>Tuesdays</b> at Duke's starting February 2024! <i><a href="https://www.facebook.com/thecoldhearted317/events" target="_blank">Check here</a> to confirm dates and times.</i></h3>

    <!-- <div><a href="https://www.bandsintown.com/a/15395815-the-cold-hearts-indy?affil_code=js_localhost&amp;app_id=js_localhost&amp;came_from=242&amp;trigger=track&amp;utm_campaign=track&amp;utm_medium=web&amp;utm_source=widget" target="_blank"><span>Track</span> to get concert, live stream and tour updates.</a></div> -->
    <div><band-schedule limit="100"></band-schedule></div>

    <!-- <a
      class="bit-widget-initializer"
      data-artist-name="The Cold Hearts Indy"
      data-display-local-dates="false"
      data-display-past-dates="false"
      data-auto-style="true"
      data-text-color="#FFFFFF"
      data-link-color="#cc8110"
      data-background-color="rgba(0,0,0,0)"
      data-display-limit="15"
      data-display-start-time="true"
      data-link-text-color="#FFFFFF"
      data-display-lineup="false"
      data-display-play-my-city="true"
      data-separator-color="rgba(124,124,124,0.25)"
    ></a> -->
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";
import BandSchedule from "../components/BandSchedule.vue";

export default {
  name: "schedule",
  components: {
    MainLayout,
    BandSchedule
  },
  mounted() {
    // let bandsintownScript = document.createElement("script");
    // bandsintownScript.setAttribute(
    //   "src",
    //   "https://widget.bandsintown.com/main.min.js"
    // );
    // document.head.appendChild(bandsintownScript)
  }
}
</script>
