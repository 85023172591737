<template>
  <main-layout>
    <!-- promo -->
    <!-- <div style="margin-bottom: 2em" >
    <h3>Listen to the latest release: Lonely Cowboys (Live)</h3>
    <a href="/media">
      <img alt="Lonely Cowboys (Live)" src="../assets/LonelyCowboysAlbumCover.png" width="350px"/>
    </a>
    </div> -->
    <!-- end promo -->

  <!-- TODO: uncomment when promo over: -->
  <img src="../assets/fullTchPick_blackBg.png" width="160px" />

    <h2>Honky Tonk &#9734; Outlaw &#9734; Classic Country Music</h2>
    <!-- <img alt="The Cold Hearts" src="../assets/tchDukesFourOfBandClose.jpg" width="100%" /> -->
    <img alt="The Cold Hearts" src="../assets/bandBannerHiFiAnnex.jpg" width="100%" />
    <h2>Follow Us</h2>
    <ul>
      <li>
        <a href="https://www.facebook.com/thecoldhearted317/" target="_blank" title="Facebook">
          <img alt="Facebook" src="../assets/flogo-RGB-HEX-Blk-72.png" />
        </a>
      </li>
      <li>
        <a
          style="font-size: 72px;"
          href="https://mailchi.mp/f09d88368509/tch"
          target="_blank"
          title="Email List"
        >&#x2709;</a>
      </li>
      <li>
        <a href="https://www.instagram.com/the_cold_hearts/" target="_blank" title="Instagram">
          <img alt="Instagram" src="../assets/insta-logo.png" width="64px" />
        </a>
      </li>
    </ul>
    <div><band-schedule limit="2"></band-schedule></div>
    <h3 style="margin-top: 15px"><a href="/schedule">See the Full Schedule Here</a></h3>

    <!-- <a
      class="bit-widget-initializer"
      data-artist-name="The Cold Hearts Indy"
      data-display-local-dates="false"
      data-display-past-dates="false"
      data-auto-style="true"
      data-text-color="#FFFFFF"
      data-link-color="#cc8110"
      data-background-color="rgba(0,0,0,0)"
      data-display-limit="2"
      data-display-start-time="true"
      data-link-text-color="#FFFFFF"
      data-display-lineup="false"
      data-display-play-my-city="false"
      data-separator-color="rgba(124,124,124,0.25)"
      data-display-logo="false"
      data-display-track-button="false"
    ></a> -->
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";
import BandSchedule from "./BandSchedule.vue";

export default {
  name: "app",
  components: {
    MainLayout,
    BandSchedule
  },
  mounted() {
    // let bandsintownScript = document.createElement("script");
    // bandsintownScript.setAttribute(
    //   "src",
    //   "https://widget.bandsintown.com/main.min.js"
    // );
    // document.head.appendChild(bandsintownScript);
  }
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: white;
  font-style: normal;
  text-decoration: none;
}
</style>