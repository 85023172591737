<template>
  <table id="tch-schedule">
      <tr style="padding-bottom: 0.7em"><th>Upcoming Dates</th></tr>
      <tr v-for="item in tchSchedule" :key="item.id">
        <td style="width: 99%">
          <div style="font-size: 20px; color: white; margin-top: .3em">{{ item.displaytime }}</div>
          <div><span v-if="item.title && item.title !== item.location">{{ item.title }} - </span>{{ item.location }}</div>
          <div style="color: white">{{ item.city }}</div>
        </td>
        <td><div class="info-button" v-if="item.detailsLink"><a target="_blank" :href="item.detailsLink" style="color: white; font-weight: bolder">
          <span style="margin-right: 1em">Details</span></a></div></td>
      </tr>
  </table>
</template>

<script>
import { initializeApp } from "firebase/app"
import { getFirestore, collection, getDocs, query, where, orderBy, limit } from "firebase/firestore"
import Vue from 'vue';

initializeApp({
  apiKey: 'AIzaSyDzMxbZrUb76M5hloubGpzIABgJkK42p4c',
  authDomain: 'the-cold-hearts.firebaseapp.com',
  projectId: 'the-cold-hearts'
})

export default {
  name: "BandSchedule",
  props: ['limit'],
  mounted() {
  },
  data() {
    const tchSchedule = new Vue({
      data: {
        tchSchedule: []
      }})

    const lim = this.limit ? Number.parseInt(this.limit) : 100
    const now = new Date()
    const nowStr = new Date(now - (now.getTimezoneOffset() * 60 * 1000)).toISOString().split('.')[0]
    const db = getFirestore()
    const q = query(collection(db, 'schedule'), where('datetime', '>=', nowStr), orderBy('datetime', 'asc'), limit(lim))
    getDocs(q).then((querySnapshot) =>
    querySnapshot.forEach(doc => {
      let d = doc.data()
      d.id = doc.id
      d.detailsLink = d.tickets ? d.tickets : d.url
      tchSchedule.tchSchedule.push(d)
    }))

    return {
      tchSchedule: tchSchedule.tchSchedule
    }
  }
};
</script>

<style scoped>
table {
  border-spacing: 0;
}

th {
  text-align: left;
  font-weight: bolder;
  padding-right: 1em;
}

tr {
  margin-left: 0.1em;
}

td {
  text-align: left;
  padding-bottom: .5em;
  padding-left: 1em;
  border-bottom: 1px solid gray;
}

.info-button {
  font-size: 0.88em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>