import Schedule from './pages/Schedule.vue';
import Home from './components/Home.vue';
import Contact from './pages/Contact.vue';
import About from './pages/About.vue';
import Songs from './pages/Songs.vue';
import Media from './pages/Media.vue';
import epk from './pages/epk.vue';
import Press from './pages/Press.vue';

export default {
    '/': {
        name: 'Home',
        view: Home,
    },
    '/schedule': {
        name: 'Schedule',
        view: Schedule,
    },
    '/contact': {
        name: 'Contact',
        view: Contact,
    },
    '/media': {
        name: 'Media',
        view: Media,
    },
    '/about': {
        name: 'About',
        view: About,
    },
    '/songs': {
        name: 'Songs',
        view: Songs,
    },
    '/epk': {
        name: 'EPK',
        view: epk,
    },
    '/press': {
        name: 'Press',
        view: Press,
    },
};