<template>
  <main-layout>
    <h2>Music</h2>
    <h3>Latest Release:<br>Lonely Cowboys (Live) - recorded live at Duke's!</h3>
    <img alt="Lonely Cowboys (Live)" src="../assets/LonelyCowboysAlbumCover.png" width="350px" style="margin-top: 0.2em; margin-bottom: 0.5em" />
    <div class="media-links" style="margin-top: 1em; margin-bottom: 1em">
      <a alt="Spotify" href="https://open.spotify.com/album/7FlSX3dGNbG0jHr1QaqPxQ" target="_blank">
        <img alt="Spotify - Lonely Cowboys (Live)" src="../assets/Spotify_Icon_RGB_Green.png" width="40px" style="margin-right: 1em" />
      </a>
      <a alt="Apple Music" href="https://music.apple.com/us/album/lonely-cowboys-live-single/1606470912" target="_blank">
        <img alt="Apple Music - Lonely Cowboys (Live)" src="../assets/Apple_Music_Icon_RGB_sm_073120.svg" width="40px" style="margin-right: 1em"/>
      </a>
      <a alt="Amazon Music" href="https://music.amazon.com/albums/B09QXVJPYD" target="_blank">
        <img alt="Amazon Music - Lonely Cowboys (Live)" src="../assets/amazon_music_icon.png" width="40px" style="margin-right: 1em"/>
      </a>
      <a alt="YouTube" href="https://youtu.be/_eaJgo2qrZU" target="_blank">
        <img alt="YouTube - Lonely Cowboys (Live)" src="../assets/yt_icon_rgb.png" width="40px" style="margin-right: 1em; vertical-align: super" />
      </a>
    </div>
    <div style="text-align: center; margin-top: 0.5em; margin-bottom: .1em;">
      <iframe src="https://www.youtube.com/embed/_eaJgo2qrZU" frameborder="0" allow="autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
    </div>

    <div style="margin-top: 0.5em; margin-bottom: 4em; margin-top: 2em" >
      <iframe style="border-radius:12px" src="https://open.spotify.com/embed/artist/0fpKGu2LYkWFkQqH9l7fow?utm_source=generator" width="75%" height="250" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
    </div>

    <!-- <img alt="Rings (Live)" src="../assets/RingsAlbumCover.png" width="300px" style="vertical-align: middle" /> -->

    <h3>Releases</h3>
    <div class="media-links" style="margin-top: 1em; margin-bottom: 3em">
    <table>
      <tr><th></th><th>Title</th><th>Year</th><th>Listen</th></tr>
      <tr><td><img alt="Lonely Cowboys (Live)" src="../assets/LonelyCowboysAlbumCover.png" width="100px" style="vertical-align: middle" /></td>
      <td style="font-size: 22px">Lonely Cowboys (Live)</td><td>2022</td>
      <td>
        <a alt="Spotify" href="https://open.spotify.com/album/7FlSX3dGNbG0jHr1QaqPxQ" target="_blank">
        <img alt="Spotify - Lonely Cowboys (Live)" src="../assets/Spotify_Icon_RGB_Green.png" width="40px" style="margin-right: 1em" />
      </a>
      <a alt="Apple Music" href="https://music.apple.com/us/album/lonely-cowboys-live-single/1606470912" target="_blank">
        <img alt="Apple Music - Lonely Cowboys (Live)" src="../assets/Apple_Music_Icon_RGB_sm_073120.svg" width="40px" style="margin-right: 1em"/>
      </a>
      <a alt="Amazon Music" href="https://music.amazon.com/albums/B09QXVJPYD" target="_blank">
        <img alt="Amazon Music - Lonely Cowboys (Live)" src="../assets/amazon_music_icon.png" width="40px" style="margin-right: 1em"/>
      </a>
      <a alt="YouTube" href="https://youtu.be/_eaJgo2qrZU" target="_blank">
        <img alt="YouTube - Lonely Cowboys (Live)" src="../assets/yt_icon_rgb.png" width="40px" style="margin-right: 1em; vertical-align: super" />
      </a>
      </td>
      </tr>

      <tr><td><img alt="Rings (Live)" src="../assets/RingsAlbumCover.png" width="100px" style="vertical-align: middle" /></td>
      <td style="font-size: 22px">Rings (Live)</td><td>2022</td>
      <td>
        <a alt="Spotify" href="https://open.spotify.com/album/3Wm9cFWwcXwIrySPeoOoVh" target="_blank">
        <img alt="Spotify - Rings (Live)" src="../assets/Spotify_Icon_RGB_Green.png" width="40px" style="margin-right: 1em" />
      </a>
      <a alt="Apple Music" href="https://music.apple.com/us/album/rings-live-single/1606470572" target="_blank">
        <img alt="Apple Music - Rings (Live)" src="../assets/Apple_Music_Icon_RGB_sm_073120.svg" width="40px" style="margin-right: 1em"/>
      </a>
      <a alt="Amazon Music" href="https://music.amazon.com/albums/B09QXT7769" target="_blank">
        <img alt="Amazon Music - Rings (Live)" src="../assets/amazon_music_icon.png" width="40px" style="margin-right: 1em"/>
      </a>
      <a alt="YouTube" href="https://youtu.be/Q5XsDnLTeIA" target="_blank">
        <img alt="YouTube - Rings (Live)" src="../assets/yt_icon_rgb.png" width="40px" style="margin-right: 1em; vertical-align: super" />
      </a>
      </td></tr>
    </table>
    </div>

    <h2>Photos</h2>
    <img alt="TCH Cheers" src="../assets/IMG_0241.jpg" width="100%" />
    <img alt="Jarod and Andy at HiFi" src="../assets/IMG_0311.jpg" width="50%" />
    <img alt="Jarod at HiFi" src="../assets/IMG_0160.jpg" width="50%" />
    <img alt="TCH at HiFi" src="../assets/img0080.jpg" width="100%" />
    <img alt="The Cold Hearts, Cash Bash 2020" src="../assets/tchCashBashFullBand.jpg" width="100%" />
    <img alt="Trio at Duke's 2021" src="../assets/trioDukes.jpg" width="100%" />
    <img alt="Bill" src="../assets/andyJarod.jpg" width="50%" />
    <img alt="Andy and Jarod" src="../assets/jarodClose.jpg" width="50%" />
    <img alt="Bill" src="../assets/tchBill.jpg" width="50%" />
    <img alt="Andy and Jarod" src="../assets/tchAndyJarod.jpg" width="50%" />
    <img alt="Jarod" src="../assets/jarodGuitar.jpg" width="100%" />

    <h2>Video</h2>
    <div class="vid-container">
      <iframe
        src="https://www.youtube.com/embed/G3qqY3iBfrQ"
        frameborder="0"
        allow="autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        class="video">
      </iframe>
    </div>
    <div class="vid-container">
      <iframe
        src="https://www.youtube.com/embed/0WzdJicZHW4"
        frameborder="0"
        allow="autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        class="video"
      ></iframe>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";

export default {
  name: "media",
  components: {
    MainLayout
  }
};
</script>

<style scoped>
.vid-container {
  position: relative;
  width: 100%;
  height: 0;
  margin-bottom: 3em;
  padding-bottom: 56.25%;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

table {
  margin-left: auto;
  margin-right: auto;
}

th {
  font-weight: bolder;
  padding-right: 1.5em;
}

tr {
  border-bottom: 1px;
  border-color: white;
}

td {
  padding-right: 1em;
  padding-bottom: .5em;
}
</style>