import Vue from 'vue';
import NotFound from './components/PageNotFound.vue';
import routes from './routes';

Vue.config.productionTip = false

const app = new Vue({
  el: '#app',
  data: {
    currentRoute: window.location.pathname,
  },
  computed: {
    ViewComponent() {
      const view = routes[this.currentRoute];
      return view ? routes[this.currentRoute].view : NotFound;
    },
  },
  render(h) { return h(this.ViewComponent); },
});

window.addEventListener('popstate', () => {
  app.currentRoute = window.location.pathname
});