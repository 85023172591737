<template>
  <div id="app">
    <div class="container">
      <div>
        <ul>
          <li>
            <v-link href="/">Home</v-link>
          </li>
          <li>&#9734;</li>
          <li>
            <v-link href="/schedule">Schedule</v-link>
          </li>
          <li>&#9734;</li>
          <li>
            <v-link href="/contact">Contact / Booking</v-link>
          </li>
          <li>&#9734;</li>
          <li>
            <v-link href="/media">Music / Media</v-link>
          </li>
          <li>&#9734;</li>
          <li>
            <v-link href="/press">EPK</v-link>
          </li>
          <li>&#9734;</li>
          <li>
            <v-link href="/about">About</v-link>
          </li>
        </ul>
        <h1>The Cold Hearts</h1>
      </div>
      <slot></slot>
      <div class="footer">Copyright © 2021 - 2024 The Cold Hearts, All rights reserved.</div>
    </div>
  </div>
</template>

<script>
import VLink from "../components/vlink.vue";

export default {
  components: {
    VLink
  }
};
</script>

<style>
#app {
  font-family: Times, serif, sans-serif;
  font-variant: small-caps;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #cc8110;
  margin-top: 0px;
  background: #3f3d3b;
  min-height: 100vh;
}
</style>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 1px 30px;
  background: black;
  min-height: 100vh;
}
.footer {
  margin: 20px;
}
h1 {
  font-size: 4em;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.about-head {
  margin-top: 0.7em;
  margin-bottom: 0.2em;
}
.about {
  font-variant: normal;
}
</style>
