<template>
  <main-layout>
    <h2>
      Thank you for considering
      <i>The Cold Hearts</i> for your event!
    </h2>
    <h3>
      Info to help promote and pull off the show (logos, stage plot, recommendation letters, etc.):
      <br />
      <a href="/files/TheColdHeartsEpk.zip" download>TheColdHeartsEpk.zip</a>
    </h3>
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";

export default {
  name: "EPK",
  components: {
    MainLayout
  }
};
</script>
