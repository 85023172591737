<template>
  <main-layout>
    <h2>
      In addition to their own music, here are just some of the songs / artists
      <i>The Cold Hearts</i> perform
    </h2>
    <ol class="songs">
      <li>Amos Moses - Jerry Reed</li>
      <li>&#9734;</li>
      <li>Another Round - Whitey Morgan</li>
      <li>&#9734;</li>
      <li>Cold, Cold Heart - Hank Williams</li>
      <li>&#9734;</li>
      <li>Crazy Arms - Ray Price</li>
      <li>&#9734;</li>
      <li>Drivin My Life Away - Eddie Rabbitt</li>
      <li>&#9734;</li>
      <li>Fast As You - Dwight Yoakam</li>
      <li>&#9734;</li>
      <li>Folsom Prison Blues - Johnny Cash</li>
      <li>&#9734;</li>
      <li>Kiss an Angel Good Morning - Charlie Pride</li>
      <li>&#9734;</li>
      <li>Lonely Cowboys - The Cold Hearts</li>
      <li>&#9734;</li>
      <li>Long White Line - Sturgill Simpson</li>
      <li>&#9734;</li>
      <li>Luckenbach, Texas - Waylon &amp; Willie</li>
      <li>&#9734;</li>
      <li>Mammas Don't Let You Babies - Willie Nelson</li>
      <li>&#9734;</li>
      <li>Motel Time Again - Johnny Paycheck</li>
      <li>&#9734;</li>
      <li>My Heart Skips A Beat - Buck Owens</li>
      <li>&#9734;</li>
      <li>Nashville Rash - Dale Watson</li>
      <li>&#9734;</li>
      <li>Neon Moon - Brooks &amp; Dunn</li>
      <li>&#9734;</li>
      <li>On the Other Hand - Randy Travis</li>
      <li>&#9734;</li>
      <li>Only Daddy That'll Walk the Line - Waylon Jennings</li>
      <li>&#9734;</li>
      <li>Rings - The Cold Hearts</li>
      <li>&#9734;</li>
      <li>She Thinks I Still Care - George Jones</li>
      <li>&#9734;</li>
      <li>Tennessee Whiskey - Chris Stapleton</li>
      <li>&#9734;</li>
      <li>Thanks A Lot - Ernest Tubb</li>
      <li>&#9734;</li>
      <li>The Fireman - George Strait</li>
      <li>&#9734;</li>
      <li>Truck Drivin' Man - Commander Cody</li>
      <li>&#9734;</li>
      <li>Tulsa Time - Don Williams</li>
      <li>&#9734;</li>
      <li>Whiskey Bent - Hank Jr.</li>
      <li>&#9734;</li>
      <li>Whitehouse Road - Tyler Childers</li>
      <li>&#9734;</li>
      <li>Wine Me Up - Faron Young</li>
      <li>&#9734;</li>
      <li>Working Man Blues - Merle Haggard</li>
    </ol>
    <h3>Have a request? Let us know!</h3>
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";

export default {
  name: "songs",
  components: {
    MainLayout
  }
};
</script>

<style scoped>
.songs li {
  display: block;
  margin: 0px;
}
</style>