<template>
  <main-layout>
    <h2>To book one of the best honky tonk, outlaw, and classic country bands around for your event in central Indiana and beyond...</h2>
    <h3>Call 317-319-7792 OR message us:</h3>
    <a href="https://www.facebook.com/thecoldhearted317/" target="_blank">
      <img alt="Facebook" src="../assets/flogo-RGB-HEX-Blk-72.png">
    </a>
    <h4>
      <i>The Cold Hearts</i> is a band in demand - don't wait to get on the schedule!
    </h4>
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";

export default {
  name: "contact",
  components: {
    MainLayout
  }
};
</script>
