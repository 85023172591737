import { render, staticRenderFns } from "./Press.vue?vue&type=template&id=05e07bc5&scoped=true"
import script from "./Press.vue?vue&type=script&lang=js"
export * from "./Press.vue?vue&type=script&lang=js"
import style0 from "./Press.vue?vue&type=style&index=0&id=05e07bc5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05e07bc5",
  null
  
)

export default component.exports