<template>
  <main-layout>
    <div style="position: relative; width: 100%; height: 0; padding-top: 129.4118%; padding-bottom: 0;
     box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16); margin-top: 1.6em; margin-bottom: 0.9em; overflow: hidden;
     border-radius: 8px; will-change: transform;">

      <iframe loading="lazy" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;"
        src="https://www.canva.com/design/DAFSORlshXQ/view?embed" allowfullscreen="allowfullscreen" allow="fullscreen">
      </iframe>
    </div>
    <a href="https://www.canva.com/design/DAFSORlshXQ/view?utm_content=DAFSORlshXQ&utm_campaign=designshare&utm_medium=embeds&utm_source=link" target="_blank" rel="noopener">The Cold Hearts EPK</a>
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";

export default {
  name: "press",
  components: {
    MainLayout
  }
};
</script>

<style scoped>
.songs {
  margin-bottom: 4em;
}
.songs li {
  display: block;
  margin: 0px;
}
</style>
