<template>
  <main-layout>
    <h2>The Band:</h2>
<div style="font-size: 1.2em; text-align:left; font-variant:normal">
<p><b>The Cold Hearts</b> were formed in Indianapolis Indiana in the fall of 2017 by Jarod Stillwell (lead vocals and rhythm guitar),
  <a href="https://www.andyfrantz.com/music">Andy Frantz</a> (lead guitar and harmony vocals), and Billy Dan Pemberton (upright and electric bass).</p>
<p>By the spring of 2018, they were regulars at Duke's honky-tonk in Indianapolis.</p>
<p>In 2018 they were joined by Cory Brown (pedal steel guitar), Mike Stilts (electric guitar and bass) and Robin Steele (drums). <b>The Cold Hearts</b> are classic country at their roots and those roots run deep through Tennessee and Texas.</p>
<p>Since 2021, they have held residencies at The Club Room at the Clyde Theatre in Fort Wayne Indiana as well as a monthly residency at Duke's that focuses on Traditional Texas two step dancing.</p>

<div style="font-size: 0.8em; display:inline-block; margin-left: 4em; margin-right:4em">"Although we are classic band and I love to do the traditional Texas two-step myself, we also are fortunate to work with some great line dance instructors at our two step Tuesday events to make dancing open to everyone." -Jarod Stillwell</div>

<p><b>The Cold Hearts</b> have been fortunate enough to open for some of their heroes, including Austin's Mike and The Moonpies, Nashville Tennessee's The Cowpokes, and other notable Ameripolitan performers.</p>
<p>In 2022 <b>The Cold Hearts</b> released two original singles on all streaming platforms that were recorded live at Duke's. A honky-tonk shuffle number called "Rings" and an outlaw cowboy number that sounds like it came right from the gun fights of the old west entitled "Lonely Cowboys."</p>
</div>

    <div style="margin-top: 3em"></div>
    <!-- <img alt="The Cold Hearts Cartoon" src="../assets/tchCartoon.jpg" width="100%" style="margin-top: 5em"/>
    <h2>Get The Cold Heart Facts Here!</h2>
    <h3>Volume 1</h3>
    <img alt="The Cold Heart Facts, Volume 1" src="../assets/TheColdHeartFacts_v1.jpg" width="100%" /> -->

  <h2>Sample of Songs Played:</h2>
    <ol class="songs">
      <li>Amos Moses - Jerry Reed</li>
      <li>&#9734;</li>
      <li>Another Round - Whitey Morgan</li>
      <li>&#9734;</li>
      <li>Cold, Cold Heart - Hank Williams</li>
      <li>&#9734;</li>
      <li>Crazy Arms - Ray Price</li>
      <li>&#9734;</li>
      <li>Drivin My Life Away - Eddie Rabbitt</li>
      <li>&#9734;</li>
      <li>Fast As You - Dwight Yoakam</li>
      <li>&#9734;</li>
      <li>Folsom Prison Blues - Johnny Cash</li>
      <li>&#9734;</li>
      <li>Kiss an Angel Good Morning - Charlie Pride</li>
      <li>&#9734;</li>
      <li>Lonely Cowboys - The Cold Hearts</li>
      <li>&#9734;</li>
      <li>Long White Line - Sturgill Simpson</li>
      <li>&#9734;</li>
      <li>Luckenbach, Texas - Waylon &amp; Willie</li>
      <li>&#9734;</li>
      <li>Mammas Don't Let You Babies - Willie Nelson</li>
      <li>&#9734;</li>
      <li>Motel Time Again - Johnny Paycheck</li>
      <li>&#9734;</li>
      <li>My Heart Skips A Beat - Buck Owens</li>
      <li>&#9734;</li>
      <li>Neon Moon - Brooks &amp; Dunn</li>
      <li>&#9734;</li>
      <li>On the Other Hand - Randy Travis</li>
      <li>&#9734;</li>
      <li>Only Daddy That'll Walk the Line - Waylon Jennings</li>
      <li>&#9734;</li>
      <li>Rings - The Cold Hearts</li>
      <li>&#9734;</li>
      <li>She Thinks I Still Care - George Jones</li>
      <li>&#9734;</li>
      <li>Tennessee Whiskey - Chris Stapleton</li>
      <li>&#9734;</li>
      <li>Thanks A Lot - Ernest Tubb</li>
      <li>&#9734;</li>
      <li>The Fireman - George Strait</li>
      <li>&#9734;</li>
      <li>Truck Drivin' Man - Commander Cody</li>
      <li>&#9734;</li>
      <li>Tulsa Time - Don Williams</li>
      <li>&#9734;</li>
      <li>Whiskey Bent - Hank Jr.</li>
      <li>&#9734;</li>
      <li>Whitehouse Road - Tyler Childers</li>
      <li>&#9734;</li>
      <li>Wine Me Up - Faron Young</li>
      <li>&#9734;</li>
      <li>Working Man Blues - Merle Haggard</li>
      <li>&#9734;</li>
      <li><b>Have a request? Let us know!</b></li>
    </ol>
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";

export default {
  name: "about",
  components: {
    MainLayout
  }
};
</script>

<style scoped>
.songs {
  margin-bottom: 4em;
}
.songs li {
  display: block;
  margin: 0px;
}
</style>
